import React from 'react'

const index = () => {
  return (
    <div style={{padding:"20px"}}>
        <h2 style={{textAlign:"center"}}>Terms & Conditions </h2>
        <br/>

    Last Updated: [18/9/2024]
    <br/>

    
   
Welcome to the Bright Future Institute mobile application (the "App"). By downloading, accessing, or using the App, you

agree to comply with and be bound by these Terms & Conditions (the "Terms"). The Terms govern your use of the App, our

website, and any services provided through the platform (collectively, the "Services"). The Services include, but are not

limited to, class registration for physical and online courses, access to supplementary digital materials (such as videos

and notes), the option to purchase add-ons (including printed notes and in-person revision sessions), and participation in

communication tools like dedicated WhatsApp groups. If you do not agree to these Terms, please refrain from using the

App.
    <br/>
    ________________________________________
    <h4>1.	Acceptance of Terms</h4>
    By using the Bright Future Institute App, you confirm that you have read, understood, and agree to be bound by these Terms. Bright Future Institute reserves the right to modify these Terms at any time, and such changes will be effective upon posting within the App. Continued use of the App after such updates constitutes your acceptance of the revised Terms.
    <h4>2. Overview of Services </h4>
    <b>Bright Future Institute is an educational platform offering:</b>
    <br/>
    <b>•	Physical Classes:</b> Includes both in-person and online virtual sessions conducted within our institute. Online

sessions involve physical virtual attendance at scheduled times.
    <br/>
    <b>•	Supplementary Digital Resources:</b> Recorded videos and notes are provided free of charge as a complementary

resource for students who have registered for physical classes. These materials are intended to enhance the

learning experience and are not sold independently. The recorded content for online subscriptions is identical to

that provided to in-person students.
    <br/>
    <h4>3. Registration and Subscriptions</h4>
    <h5>3.1 Account Registration</h5>
    To access certain features of the App, users must register for an account. You agree to provide accurate, current, and complete information during registration and to update this information as necessary. You are responsible for keeping your login credentials confidential and for all activities that occur under your account.
    <h5>3.2 Subscription Models</h5>
    <b>The App offers two primary subscription options:</b>
    <br/>
    <b>•	Online Subscription:</b> Students register for virtual sessions held at the Institute. As part of this subscription,

students gain complementary access to recorded sessions and digital notes provided via the App. These digital

materials are free of charge and serve to support the physical course. Printed notes and in-person revisions can be

purchased as separate add-ons.
   <br/> <b>•	In-Person Subscription:</b> Allows students to register for physical attendance at the Institute, which includes:
   <br/>
   <b>Recorded Videos:</b> Access to the same videos provided in the online subscription.
   <br/>

   <b>• Printed Notes:</b> Provided free of charge as part of the subscription.
<br/>

<b>• In-Person Revisions:</b> Access to free in-person revision sessions.
<br/>

<b>• Additional Physical Classes:</b> Any necessary additional classes agreed upon between students and instructors

will be provided for free.
<br/>
    
    <h4>4. Payment and Fees</h4>
    <h5>4.1 External Payment Methods</h5>
    Payments for physical class subscriptions and any related physical add-ons (such as printed notes or in-person revision

sessions) are processed externally through MyFatoorah. The app does not offer standalone digital products for purchase,

as all digital content is provided as a free complement to physical services.
    <h5>4.2 Payment Terms</h5>
    Payments for subscriptions and services are due at the time of registration. We also offer an installment payment option

for students, allowing payment in smaller amounts over time. All payments are non-refundable, except as required by law.
    <br/>
    <h4>5. User Conduct and Responsibilities</h4>
    <b> By using the App, you agree:</b>
    <br/>
    •	To use the App for lawful purposes only.
    <br/>
    •	Not to disrupt or interfere with the App's operations or servers.
    <br/>
    •	Not to impersonate any individual or entity or misrepresent your affiliation with any person or organization.
    You are solely responsible for any activities carried out under your account, including maintaining the confidentiality of your login information.
    <h4>6. Content and Intellectual Property</h4>
    All content available on the App, including but not limited to digital notes, videos, and learning materials, is provided

solely as a complementary feature for students registered for physical classes. This content is not sold independently

and is intended only to supplement the physical learning experience.
    <h4>7. User-Generated Content</h4>
    If you upload, post, or submit any content to the App (such as reviews or comments), you grant Bright Future Institute a worldwide, non-exclusive, royalty-free license to use, reproduce, display, and distribute that content in connection with the App and its services.
    <h4>8. Privacy Policy</h4>
    Your use of the App is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal data. Please read the Privacy Policy carefully to understand our practices.
    <h4>9. Limitation of Liability</h4>
    To the fullest extent permitted by law, Bright Future Institute will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, revenue, data, or use, arising from your use of the App or Services.
    <h4>10. Termination</h4>
    We reserve the right to terminate or suspend your account or access to the App without notice if we believe you have violated these Terms or if your use of the App harms other users or violates any applicable laws or regulations.
    <h4>11. Refund Policy</h4>
    Refunds for course subscriptions or add-ons are generally not allowed, except under specific circumstances. To request a refund, you must submit a formal request to the App administration ("Admin") through the appropriate contact channels within the App. Each request will be reviewed on a case-by-case basis, and refunds may be granted at the sole discretion of Bright Future Institute if the request is deemed valid (e.g., technical issues, course cancellations, etc.). 
    <h4>12. Modifications to the Services</h4>
    Bright Future Institute reserves the right to modify or discontinue any part of the App or Services at any time without prior notice. We are not liable for any changes, suspension, or discontinuation of the App.
    <h4>13. Contact Information</h4>
    If you have any questions about these Terms, please contact us at:
    <br/>
    <b>•	Email:</b> info@brightfuturekw.net
    <br/>
    <b>•	Phone:</b> (+965 96771010), (+965 96777047)
    <br/>
    <b>•	Address:</b> Kuwait, Sabah Al-Salem, Block 2, Street 208, Building 199, 1st Floor
    </div>
  )
}

export default index