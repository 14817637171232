import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";


import {
	addInstructor,
	editInstructor,
	getAllUniversities,
	getInstructor,
} from "../../api";
import InstructorDetailsSchema from "../../validations/addInstructor";
import { AddInstructorPayload, DepartmentInterface } from "../../api/interface";
import { UniversityInterface } from "../../api/interface";
import { MESSAGES } from "../../../../utils/messages";
import styles from "../../styles/instructor.module.css";
import commonstyles from "../../styles/common.module.css";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { YUP } from "../../../../utils/validations";
import { APP_ROUTES } from "../../../../utils/routes";
import Layout from "../../../../components/Layout";
import AddImage from "./UploadImageModal";
import { uploadImage } from "../../../common/utils";




const AddInstructor = () => {

	
	
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	const [croppedImage,setCroppedImage]= useState<File | null>(null)
	

	
	const { id } = useParams();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState<boolean>(false);
	const handleClose = () => {
		setShowModal(false);}
	const instructorDetailsFormik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			university: 0,
			department: [],
			email: "",
			phoneNumber: "",
			password: "",
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const mobileNumber = parsePhoneNumber(values.phoneNumber);

			const payload: AddInstructorPayload = {
				first_name: values.firstName,
				last_name: values.lastName,
				password: values.password,
				departments: selectedDepartments,
				email: values.email,
				universities: [Number(values.university)],
				country_code: mobileNumber?.countryCallingCode as string,
				mobile: mobileNumber?.nationalNumber as string,

			};
			if (selectedDepartments.length === 0) {
				instructorDetailsFormik.setFieldError(
					INSTRUCTOR.FORMIK.DEPARTMENT,
					YUP.REQUIRED
				);
				return;
			}
			
			uploadImage(croppedImage).then((res)=>{
				payload.avatar=res.data[0]
				toast.promise(
				!id ? addInstructor(payload) : editInstructor(Number(id), payload),
				{
					pending: {
						render() {
							return !id ? MESSAGES.INSTRUCTOR.ADD : MESSAGES.INSTRUCTOR.EDIT;
						},
					},
					success: {
						render() {
							instructorDetailsFormik.resetForm();
							navigate(APP_ROUTES.INSTRUCTORS_LIST);

							return !id
								? MESSAGES.INSTRUCTOR.SUCCESS
								: MESSAGES.INSTRUCTOR.EDITED;
						},
					},
					error: {
						render() {
							return !id
								? MESSAGES.INSTRUCTOR.INVALID
								: MESSAGES.INSTRUCTOR.NOTEDITED;
						},
					},
				}
			);
			})
			
		},
		validationSchema: InstructorDetailsSchema,
	});

	const handleUniversityChange = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		instructorDetailsFormik.setFieldValue(
			INSTRUCTOR.FORMIK.UNIVERSITY,
			Number(e.target.value)
		);
		instructorDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
		addDepartments(Number(e.target.value));
	};

	const addDepartments = (id: Number) => {	
		const selectedUniversity = universities.filter(
			(uni: UniversityInterface) => {
				return Number(uni.id) === Number(id);
			}
		);
		console.log(universities, selectedUniversity, "compare")
		console.log(selectedUniversity[0]?.universityDepartments, "suuuu")
		setDepartment(selectedUniversity[0]?.universityDepartments);
	};

	const handleAddInstructor = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		instructorDetailsFormik.submitForm();
	};

	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		})
	
	}, []);

	useEffect(() => {
		if (id) {
			getInstructor({ id: Number(id) }).then((res) => {
				const {
					data: {
						country_code,
						mobile,
						instructorUniversities,
						first_name,
						last_name,
						email,
						instructorDepartments,
					},
				} = res;
				addDepartments(instructorUniversities[0].university_id);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.FIRSTNAME,
					first_name
				);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.LASTNAME,
					last_name
				);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.PHONENUMBER,
					`${country_code && "+"}${country_code}${mobile}`
				);
				instructorDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.EMAIL, email);
				instructorDetailsFormik.setFieldValue(
					INSTRUCTOR.FORMIK.UNIVERSITY,
					instructorUniversities[0].university_id
				);
				addDepartments(Number(instructorUniversities[0].university_id));
				const depList = instructorDepartments.map((dep) => dep.department_id);
				if (depList.length) setSelectedDepartments(depList as number[]);
				//instructorDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, instructorDepartments[0].department_id)
			});
		}

		if (!id) {
			instructorDetailsFormik.resetForm();
			setDepartment([]);
			setSelectedDepartments([]);
		}
	
	}, [universities])
	

	const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		instructorDetailsFormik.setFieldError(INSTRUCTOR.FORMIK.DEPARTMENT, "");
		if (e.target.checked === true) {
			setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
		} else {
			const selectedDepartmentsCopy = selectedDepartments;
			const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
				return num !== Number(e.target.value);
			});
			setSelectedDepartments(selectedDepartmentsList);
		}
	};

	return (
		<Layout>
			<div
				className={`${styles["add-lead-from-page"]} ${styles["add-lead-from"]}`}
			>
				<h1>{id ? "Edit Instructor" : "Add New Instructor"}</h1>

				<Form onSubmit={handleAddInstructor}>
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>First name</Form.Label>
							<Form.Control
								type="text"
								placeholder="First Name"
								value={instructorDetailsFormik.values.firstName}
								name="firstName"
								onChange={instructorDetailsFormik.handleChange}
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.firstName &&
          instructorDetailsFormik.touched.firstName ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.firstName}
								</span>
							) : null}
					</div>
					<Button onClick={()=>setShowModal(true)} className={`${styles.buttonss} mt-3`}>
						{croppedImage?"Update Profile Image":"Upload Profile Image"}
						
					</Button>

					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formLastName">
							<Form.Label>Last name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Last Name"
								value={instructorDetailsFormik.values.lastName}
								onChange={instructorDetailsFormik.handleChange}
								name="lastName"
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.lastName &&
          instructorDetailsFormik.touched.lastName ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.lastName}
								</span>
							) : null}
					</div>

					<div className={styles.fieldStyle}>
						<Form.Group controlId="formPhoneNumber">
							<Form.Label>Phone Number</Form.Label>
							<PhoneInput
								international
								placeholder="Enter phone number"
								value={instructorDetailsFormik.values.phoneNumber}
								name="phoneNumber"
								onChange={(value) =>
									instructorDetailsFormik.setFieldValue(
										INSTRUCTOR.FORMIK.PHONENUMBER,
										value
									)
								}
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.phoneNumber &&
          instructorDetailsFormik.touched.phoneNumber ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.phoneNumber}
								</span>
							) : null}
					</div>

					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formEmail">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="text"
								placeholder="Email"
								value={instructorDetailsFormik.values.email}
								onChange={instructorDetailsFormik.handleChange}
								name="email"
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.email &&
          instructorDetailsFormik.touched.email ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.email}
								</span>
							) : null}
					</div>

					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password"
								value={instructorDetailsFormik.values.password}
								name="password"
								onChange={instructorDetailsFormik.handleChange}
							/>
						</Form.Group>
						{instructorDetailsFormik.errors.password &&
          instructorDetailsFormik.touched.password ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.password}
								</span>
							) : null}
					</div>
					<hr />
					<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formUniversity">
							<Form.Label>University</Form.Label>
							<Form.Control
								value={instructorDetailsFormik.values.university}
								as="select"
								onChange={handleUniversityChange}
							>
								<option value={0} key={0}>
                Select University
								</option>
								{universities?.map((university, id) => (
									<option value={university.id} key={id}>
										{university.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						{instructorDetailsFormik.errors.university &&
          instructorDetailsFormik.touched.university ? (
								<span className={commonstyles.error}>
									{instructorDetailsFormik.errors.university}
								</span>
							) : null}
					</div>
					{instructorDetailsFormik.values.university > 0 && (
						<div className={styles.fieldStyle}>
							<Form.Label>Departments</Form.Label>

							{department?.map((res, id) => {
								return (
									<Form.Check
										key={id}
										className="from-check"
										value={res.id as number}
										type="checkbox"
										checked={
											selectedDepartments.includes(res.id as number)
												? true
												: false
										}
										onChange={handleCheckboxClick}
										label={res.name}
									></Form.Check>
								);
							})}

							{instructorDetailsFormik.errors.department &&
            instructorDetailsFormik.touched.department ? (
									<span className={commonstyles.error}>
										{instructorDetailsFormik.errors.department}
									</span>
								) : null}
						</div>
					)}
					{/* <Button variant="outline-secondary" className="mb-2">
							<i className="fas fa-plus"></i> Choose More Department
						</Button> */}
					<hr />
					{/* <Button variant="outline-secondary" className="mb-2">
							<i className="fas fa-plus"></i> Add More University
						</Button> */}
					<Button className={`${styles.buttonss} mt-3`} type="submit">
						{id ? INSTRUCTOR.EDIT : INSTRUCTOR.ADD}
					</Button>
				</Form>
			</div>
			<AddImage croppedImage={croppedImage} setCroppedImage={setCroppedImage} showModal={showModal} handleClose={handleClose} />
		</Layout>
	);
};

export default AddInstructor;
