import React, { useState } from 'react'
import { getCroppedImg, getRotatedImage } from './canvasUtils';
import { getOrientation } from 'get-orientation/browser';
import Cropper from 'react-easy-crop';
import { Slider, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import ImgDialog from './ImgDialog';
import styless from './styles.module.css'
import styles from '../../features/instructor/styles/instructor.module.css'
interface AddImageProp{
    croppedImage?:File | null;
    setCroppedImage: React.Dispatch<React.SetStateAction<File | null>>;
    handleClose: () => void;
}
const ImageUploader = ({setCroppedImage,handleClose}: AddImageProp) => {
    enum Orientation {
		TOP_LEFT = 1,
		TOP_RIGHT = 2,
		BOTTOM_RIGHT = 3,
		BOTTOM_LEFT = 4,
		LEFT_TOP = 5,
		RIGHT_TOP = 6,
		RIGHT_BOTTOM = 7,
		LEFT_BOTTOM = 8
	  }
	  const ORIENTATION_TO_ANGLE: { [key in Orientation]?: number } = {
		[Orientation.BOTTOM_RIGHT]: 180,
		[Orientation.RIGHT_TOP]: 90,
		[Orientation.LEFT_BOTTOM]: -90,
	  };
	interface CropArea {
		x: number;
		y: number;
		width: number;
		height: number;
	  }
      const [imageSrc, setImageSrc] = useState<string | null>(null);
      const [crop, setCrop] = useState({ x: 0, y: 0 });
      const [rotation, setRotation] = useState<number>(0);
      const [zoom, setZoom] = useState<number>(1);
      const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(null);
      const [croppedImages,setCroppedImages]= useState<string | null>(null)



      const onCropComplete = (croppedArea: CropArea, croppedAreaPixels: CropArea) => {
		setCroppedAreaPixels(croppedAreaPixels);
	  };
	  function readFile(file: File): Promise<string | ArrayBuffer | null> {
		return new Promise((resolve) => {
		  const reader = new FileReader();
		  reader.addEventListener('load', () => resolve(reader.result), false);
		  reader.readAsDataURL(file);
		});
	  }
	
	  // Function to show the cropped image
	  const showCroppedImage = async () => {
		if (!imageSrc || !croppedAreaPixels) return;
	
		try {
		  const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
		  console.log('done', { croppedImage });
		  setCroppedImages(croppedImage);
		} catch (e) {
		  console.error(e);
		}
	  };
      const saveCroppedImage = async () => {
		if (!imageSrc || !croppedAreaPixels) return;
	
		try {
		  const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
		  console.log('done', { croppedImage });
          if (!croppedImage) {
            throw new Error("Blob URL is null or undefined.");
        }
          const response = await fetch(croppedImage);
          const blob = await response.blob();

    // Create a File from the Blob
    const file = new File([blob], "file", { type: blob.type });
		  setCroppedImage(file);
		} catch (e) {
		  console.error(e);
		}
	  };
	
	  // Function to handle closing the cropped image
	  const onClose = () => {
		setCroppedImage(null);
	  };
	
	  // Function to handle file change and process the image
	  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
	
		if (files && files.length > 0) {
		  const file = files[0];
		  let imageDataUrl = await readFile(file);
	
		  try {
			// Apply rotation if needed
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation as Orientation] || 0;
			if (rotation) {
			  imageDataUrl = await getRotatedImage(imageDataUrl as string, rotation);
			}
		  } catch (e) {
			console.warn('Failed to detect the orientation');
		  }
	
		  setImageSrc(imageDataUrl as string);
		}
	  };
  return (
    <>
    <div>
        {imageSrc ? (
        <React.Fragment>
          
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            style={{containerStyle:{top:200,bottom:-280}}}
            
            />
         
          <div className={styless.controls}>
            <div className={styless.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: styless.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: styless.slider }}
                onChange={(e, zoom) => setZoom(zoom as number)}
              />
            </div>
            <div className={styless.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: styless.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: styless.slider }}
                onChange={(e, rotation) => setRotation(rotation as number)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              
            >
              Show Result
            </Button>
          </div>
          <ImgDialog img={croppedImages} onClose={onClose} />
        </React.Fragment>
      ) : (
        <input type="file" onChange={onFileChange} accept="image/*" />
      )}
        
    </div>
    <div className="form-group">
    <Button  className={`${styles.buttonss}  ${styles.buttonssmargin} mt-3`} onClick={()=>{saveCroppedImage().then(()=>{handleClose()})}}>Proceed</Button>
</div></>
  )
}

export default ImageUploader