export const API_ROUTES = {
	COMMON:{
		GETALLUNIVERSITIES:"/admin/universities",
		GETALLCOURSES:"/admin/courses",
		GETALLSCHOOLS:"/schools-list",
		GETTRAININGFIELD:"/training-fields",
		UPLOAD_FILE:"upload-file"
	},
	INSTRUCTOR:{
		GETLIST:"/admin/instructors",
		ADDINSTRUCTOR:"/admin/instructors",
		REMOVEINSTRUCTOR:"/admin/instructors/",
		GETINSTRUCTOR:"/admin/instructors/",
		EDITINSTRUCTOR:"/admin/instructors/"
	},
	COURSES:{
		GETCOURSELIST:"/courses",
		GETADDONSLIST:"/ads-ons",
		GETCOURSE:"/courses/",
		APPROVECOURSE:"/admin/approve-course-price",
		REMOVECOURSE:"/admin/courses/",
		GETCHAPTERS:"/admin/chapters",
		PURCHASEDCOURSES:"/purchased-courses",
		CHANGESTATUS:"/change-purchased-course-status",
		CHANGEPROFILESTATUS:"/admin/change-status",
		BUYCOURSE:"/admin/buy-course",
		GETSTUDENTS:"/admin/purchased-course-students",
		CHANGE_STATUS:"course/change-state/"
	},
	STUDENT:{
		GETLIST:"/admin/students",
		REMOVE:"/admin/students/",
		ADD:"/admin/students",
		EDIT:"/admin/students/",
		GETSTUDENT:"/admin/students/",
		GET_INSTALMENT:"/get-installment",
		EDIT_INSTALMENT_STATUS:"/change-installment-status",
		UPDATE_INSTALMENT:"/update-installment",
		DELETE_INSTALMENT:"/delete-installment"
	},
	AUTH:{
		LOGIN:"/admin/login",
		CHANGE_PASSWORD:"/change-password"
	},
	UNIVERSITIES:{
		DEPARTMENTS:"/departments",
		CATEGORY:"/admin/category",
		ADMINDEPARTMENTS:"/admin/departments",
		ADMINUNIVERSITIES:"/admin/universities",
		COMMONDEPARTMENTS:"/admin/update-common",
		COMMON_DEPARTMENT:"/admin/store-common",
		ADMIN_STORY:"/admin/story",
		STORY:"/story"
	},
	COUPONS:{
		GETLIST:"/admin/coupons",
		SEGMENT:"/admin/segment",
		SETTIMER:"/admin/set-timer",
		GETTIMER:"/admin/get-timer"
	},
	INVOICE:{
		GET_INVOICES:"/get-invoices",
		CREATE_INVOICE:"/create-invoice"
	},
	TRANSACTIONS:{
		GET_TRANSACTIONS:"/transactions"
	},
	DASHBOARD:{
		GET_TRANSACTION_GRAPH:"/admin/dashboard"
	}
};