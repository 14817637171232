import React, { useState } from 'react'
import { withStyles } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'


function Transition(props:any) {
  return <Slide direction="up" {...props} />
}


const ImgDialog = ({ img, onClose }:any) => {
  
  return (
    <div>
      <Dialog
      fullScreen
      open={!!img}
      onClose={onClose}
      TransitionComponent={Transition}
    >
        <div>
        <AppBar position='fixed'>
          <Toolbar>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" color="inherit" flex={1}>
              Cropped image
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'}}>
          <img src={img} alt="Cropped" style={{maxWidth:"100%",maxHeight:'100%'}} />
        </div>
      </div>
    </Dialog>
    </div>
  )
}

export default ImgDialog
