import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addCommonDepartment, addDepartment, editCommonDepartment, editDepartment, getCategory, getDepartment } from "../api";
import {DepartmentDetailsSchema} from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";

const AddOrEditCommonDepartment = ({ showModal, handleClose }: AddUniversityProp) => {
	const selectedDepartment = useAppSelector((state) => state.departmentReducer);

	useEffect(() => {
		if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			getCategory(Number(selectedDepartment.id)).then((res) => {
				const {
					data: { name, department_type },
				} = res;

				departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
				departmentDetailsFormik.setFieldValue("department_type", department_type);

			});
		} else {
			departmentDetailsFormik.resetForm();
		}
	}, [showModal]);

	// const handleAddBatch=()=>{
	// 	const array=departmentDetailsFormik.values.batches
	// 	departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, [...array, { startYear: "", endYear: "" }])
	// }


	const departmentDetailsFormik = useFormik<Department>({
		initialValues: {
			name: "",
			department_type:1,
			// batches:[ { startYear: "", endYear: "" }]
            file:null


            
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			console.log(values)
            if(values.file){
            uploadImage(values.file).then((res)=>{
                const payload={
                    name:values.name,
                    file:res.data[0]
                }
                toast.promise(
                    selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                        ? addCommonDepartment(payload)
                        : editCommonDepartment( selectedDepartment.id, payload),
                    {
                        pending: {
                            render() {
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.ADD
                                    : MESSAGES.DEPARTMENT.EDIT;
                            },
                        },
                        success: {
                            render() {
                                departmentDetailsFormik.resetForm();
                                handleClose();
    
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.SUCCESS
                                    : MESSAGES.DEPARTMENT.EDITED;
                            },
                        },
                        error: {
                            render() {
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.INVALID
                                    : MESSAGES.DEPARTMENT.NOTEDITED;
                            },
                        },
                    }
                );
            })}
            else{
                const payload={
                    name:values.name,
                    department_type:Number(values.department_type),
                   
                }
                toast.promise(
                    selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                        ? addCommonDepartment(payload)
                        : editCommonDepartment( selectedDepartment.id, payload),
                    {
                        pending: {
                            render() {
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.ADD
                                    : MESSAGES.DEPARTMENT.EDIT;
                            },
                        },
                        success: {
                            render() {
                                departmentDetailsFormik.resetForm();
                                handleClose();
    
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.SUCCESS
                                    : MESSAGES.DEPARTMENT.EDITED;
                            },
                        },
                        error: {
                            render() {
                                return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                                    ? MESSAGES.DEPARTMENT.INVALID
                                    : MESSAGES.DEPARTMENT.NOTEDITED;
                            },
                        },
                    }
                );
            }

		
		},

		validationSchema: DepartmentDetailsSchema,
	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};
	// const removeBatch=(index:any)=>{
	// 	if(index!==0){
	// 		const na=departmentDetailsFormik.values.batches.filter((batch, inn)=>{
	// 			return index!==inn;
	// 		})
	// 		departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, na)
	// 	}

	// }
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Department"
							: "Add New Department"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
						
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={departmentDetailsFormik.values.name}
									name={UNIVERSITY.FORMIK.NAME}
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.name &&
              departmentDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.name}
									</span>
								) : null}
							{/* <Button className={` ${styles.marginTop}`}  onClick={handleAddBatch}>Add Batch</Button> */}
						</div>
					
                        <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Logo</Form.Label>
								<Form.Control
									type="file"
									placeholder="Logo"
									accept="image/*"
									
									name="file"
									onChange={(event:any) => {
										departmentDetailsFormik.setFieldValue("file", event.currentTarget.files[0]);
									  }}
								/>
							</Form.Group>
						{/* {departmentDetailsFormik.values.batches.map((batch, index)=>{

							return <Form.Group key={index} className="from-group" controlId="formFirstName">
								<Form.Label>Start Year</Form.Label>
								<Button className={`${styles.marginBottom} ${styles.marginLeft} ${styles.marginTop}`} onClick={()=>{removeBatch(index)}}>Remove</Button>

								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.startYear`} value={batch.startYear} min="1900" max="2099" step="1" 
								/>
								<Form.Label>End Year</Form.Label>
								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.endYear`} value={batch.endYear} min="1900" max="2099" step="1"
								/>
							</Form.Group>
						})
						} */}
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditCommonDepartment;
