// Define types for createImage function
export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
  
  // Function to convert degrees to radians
  export function getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
  }
  
  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  export function rotateSize(width: number, height: number, rotation: number): { width: number; height: number } {
    const rotRad = getRadianAngle(rotation);
  
    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
  }
  
  // Define types for flip argument
  interface Flip {
    horizontal: boolean;
    vertical: boolean;
  }
  
  // Define types for pixelCrop argument
  interface PixelCrop {
    width: number;
    height: number;
    x: number;
    y: number;
  }
  
  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   */
  export async function getCroppedImg(
    imageSrc: string,
    pixelCrop: PixelCrop,
    rotation: number = 0,
    flip: Flip = { horizontal: false, vertical: false }
  ): Promise<string | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    if (!ctx) {
      return null;
    }
  
    const rotRad = getRadianAngle(rotation);
  
    // Calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);
  
    // Set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
  
    // Translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);
  
    // Draw rotated image
    ctx.drawImage(image, 0, 0);
  
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
  
    if (!croppedCtx) {
      return null;
    }
  
    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;
  
    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
  
    // Return cropped image as a Blob URL
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob(
        (file) => {
          if (file) {
            resolve(URL.createObjectURL(file));
          } else {
            reject(new Error('Blob creation failed'));
          }
        },
        'image/png'
      );
    });
  }
  
  // Function to get a rotated image
  export async function getRotatedImage(imageSrc: string, rotation: number = 0): Promise<string> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
    
    if (orientationChanged) {
      canvas.width = image.height;
      canvas.height = image.width;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }
  
    ctx?.translate(canvas.width / 2, canvas.height / 2);
    ctx?.rotate((rotation * Math.PI) / 180);
    ctx?.drawImage(image, -image.width / 2, -image.height / 2);
  
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (file) => {
          if (file) {
            resolve(URL.createObjectURL(file));
          } else {
            reject(new Error('Blob creation failed'));
          }
        },
        'image/png'
      );
    });
  }
  