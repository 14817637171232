import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsisH,
	faPlus,
	faEdit,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";

import Layout from "../../../../../components/Layout";
import commonStyles from "../../../../common/styles/common.module.css";
// // import { removeStudent, studentList } from "../api";
// import { PaginationInterface } from "../../../components/Pagination/interfaces";
// // import { Student} from "../api/interface";
import Pagination from "../../../../../components/Pagination";
// import { MESSAGES } from "../../../utils/messages";
import { AddOrEdit } from "../modals";
// import { INSTRUCTOR } from "../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
// import { removeUniversity, universityList } from "../api";
// import { University } from "../api/interface";
import { segmentationModal } from "../../../../../redux/reducers/segmentation";
// import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { segmentList } from "../api";
import { Segment } from "../api/interface";
import { INSTRUCTOR } from "../../../../../utils/instructor";
import DeleteSegment from "../modals/DeleteSegment";
import { useParams } from "react-router-dom";
//import { studentList } from "../api";

const SegmentList = () => {
	const selectedSegment = useAppSelector((state) => state.segmentationReducer);
	const {id}= useParams()
	const [pagination, setPagination] = useState<PaginationInterface>({
		page: 1,
		limit: 10,
		count: 0,
		totalPages: 0,
	});
	 const dispatch = useAppDispatch();
	// const selectedUniversity = useAppSelector((state) => state.universityReducer);
	//const navigate = useNavigate();
	//const [instructors, setInstructors] = useState<Instructor[]>([]);
	// const [universities, setUniversities] = useState<University[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [segment, setSegment]=useState<Segment[]>([])


	const refresh = () => {
		setLoading(true);
		const query = {
			per_page: pagination.limit,
			page: pagination.page,
		};

		segmentList(query,Number(id)).then((res) => {
			const {
				paginate: { total, last_page },
				data,
			} = res;
			setPagination({ ...pagination, count: total, totalPages: last_page });
			// setUniversities(data);
			setSegment(data.data);
			setLoading(false);
		});
	};

	const handleRemoveSegment = (id: Number) => {
		dispatch(
			segmentationModal({
				id: id,
				editOrAdd: INSTRUCTOR.REDUX.EDIT,
			})
		);
		setShowRemoveModal(true);
	};

	useEffect(() => {
		refresh();
	}, [pagination.page]);

	const handleClose = () => {
		setShowModal(false);
		if (selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
			if (pagination.page === 1) {
				refresh();
			} else {
				setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
			}
		} else {
			refresh();
		}
	};
	const handleRemoveClose = () => {
		setShowRemoveModal(false);
		if (pagination.page === 1) {
			refresh();
		} else {
			setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
		}
	};

	return (
		<Layout>
			<div className={commonStyles["outtre-pad"]}>
				<div className={commonStyles["heaing-filter"]}>
					<h3>Segmentation Management</h3>
					{/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
				</div>

				{!loading ? (
					<div className={commonStyles["table-data"]}>
						<div className={`${commonStyles["property-head"]} pb-1`}>
							<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
								<li className="nav-item" role="presentation">
									{/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
								</li>
							</ul>
							<div className={commonStyles["filter-pro"]}>
								<button
									onClick={() => {
										dispatch(
											segmentationModal({
												id: 0,
												editOrAdd: INSTRUCTOR.REDUX.ADD,
											})
										);
										setShowModal(true);
									}}
									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faPlus} /> Add New Segment
								</button>
							</div>
						</div>
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane fade show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<table>
									<thead>
										<tr>
											<th>Minimum Members</th>
											<th>Maximum Members</th>
											<th>Discount</th>
											<th>Unit</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{segment.map((item) => (
											
											<tr key={item.id}>
												<td>{item.min}</td>
												<td>{item.max}</td>
												<td>{item.discount}</td>
												<td>{item.discount_type===2?"%":"KD"}</td>
												
											
												<td>
													<Dropdown
														drop={"down-centered"}
														className={commonStyles.tddropdown}
													>
														<Dropdown.Toggle>
															<FontAwesomeIcon icon={faEllipsisH} />
														</Dropdown.Toggle>

														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() => {
																	dispatch(
																		segmentationModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																	size="xs"
																/>
                                Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveSegment(item.id)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item>
															
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				) : (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" role="status">
						</Spinner>
					</div>
				)}
			</div>
			<Pagination
				paginationState={pagination}
				setPaginationState={setPagination}
			/>
			<AddOrEdit showModal={showModal} handleClose={handleClose} />
			<DeleteSegment showModal={showRemoveModal} handleClose={handleRemoveClose} />

		</Layout>
	);
};

export default SegmentList;
