import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPlus,
  faEdit,
  faTrash,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
// import { removeStudent, studentList } from "../api";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { Student} from "../api/interface";
import Pagination from "../../../components/Pagination";
import { MESSAGES } from "../../../utils/messages";
import { AddOrEdit } from "../modals";
import { INSTRUCTOR } from "../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { removeUniversity, universityList } from "../api";
import { University } from "../api/interface";
import { universityModal } from "../../../redux/reducers/university";
import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../../assets/images";

//import { PaginationInterface } from "../../../components/Pagination/interfaces";
//import { studentList } from "../api";

const StudentList = () => {
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const dispatch = useAppDispatch();
  const selectedUniversity = useAppSelector((state) => state.universityReducer);
  const searchText = useAppSelector((state) => state.searchReducer.text);

  const navigate = useNavigate();

  //const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [universities, setUniversities] = useState<University[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");

useEffect(() => {
 setSearchValue(searchText as string) 
 
}, [searchText])



  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      name: searchValue,
    };

    universityList(query).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setUniversities(data);
      setLoading(false);
    });
  };

  const handleRemoveUniversity = (id: Number) => {
    toast.promise(removeUniversity({ id: id }), {
      pending: {
        render() {
          return MESSAGES.UNIVERSITY.REMOVING;
        },
      },
      success: {
        render({}) {
          refresh();
          return MESSAGES.UNIVERSITY.REMOVED;
        },
      },
      error: {
        render() {
          return MESSAGES.UNIVERSITY.NOTREMOVED;
        },
      },
    });
  };

  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };

  return (
    <Layout>
      <div  className={commonStyles["outtre-pad"]}>
	  {
          <>
            <span className="instr_manager d-flex justify-content-between align-items-center">
              <h3>University Management</h3>

              <div className={commonStyles["heaing-filter"]}>
                {/* <div className={commonStyles["filter-left"]}>
                  <span>
                    Filter:
                    <select>
                      <option>Location</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Price</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Department</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>This Week</option>
                      <option>This Week</option>
                    </select>
                  </span>
                </div> */}
              </div>
            </span>
          </>
        }
        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                {/* <InputGroup className={`${commonStyles["search-bar"]} mb-3`}>
                  <Form.Control
                    placeholder="Search Name"
                    type="text"
                    value={search}
                    aria-label="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={handleSearch}
                    variant="outline-secondary"
                    id="button-addon2"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup> */}
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    dispatch(
                      universityModal({
                        id: 0,
                        editOrAdd: INSTRUCTOR.REDUX.ADD,
                      })
                    );
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New University
                </button>
                
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <table>
                  <thead>
                    <tr>
                      <th>Logo / Name</th>
                      <th>Departments</th>
					  <th>Instructors</th>
					  <th>Courses</th>
					  <th>Registered Students</th>
                      <th>Enrolled Students</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {universities.map((item) => (
                      <tr key={item.id}>
                        <td><img
                              src={item.file ? item.file : Avatar}
                              height={40}
                              width={40}
                              alt="avatar"
                            />{item.name}</td>
                        <td>{item.universityDepartments.length}</td>
						<td>{item.total_instructor}</td>
						<td>{item.total_course}</td>
						<td>{item.registered_student}</td>
						<td>{item.enrolled_students}</td>
                        {/* <td>
												<span className={commonStyles["bl-shd"]}>
													{"View Courses"}
												</span>
											</td> */}
                        <td>
                          <Dropdown
                            drop={"down-centered"}
                            className={commonStyles.tddropdown}
                          >
                            <Dropdown.Toggle>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(
                                    universityModal({
                                      id: item.id,
                                      editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                    })
                                  );
                                  setShowModal(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  className={commonStyles.iconmargin}
                                  icon={faEdit}
                                  size="xs"
                                />
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleRemoveUniversity(item.id)}
                              >
                                <FontAwesomeIcon
                                  className={commonStyles.iconmargin}
                                  size="xs"
                                  icon={faTrash}
                                />
                                Remove
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${ROUTE_CONSTANTS.UNIVERSITIES}/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faInfoCircle}
                                />
                                View Departments
                              </Dropdown.Item>
							  <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${"/universities/students"}/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faInfoCircle}
                                />
                                View Students
                              </Dropdown.Item>
							  <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${"/universities/courses"}/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faInfoCircle}
                                />
                                View Courses
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(
                                    `${"/universities/story"}/${item.id}`
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  size="xs"
                                  className={commonStyles.iconmargin}
                                  icon={faInfoCircle}
                                />
                                View Story
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <AddOrEdit showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default StudentList;
